import { useEffect, useRef } from "react";

import { checkIOSPlatform } from "../../utils/ios";
import { getClipboardText } from "../../utils/capabilities";

export const useClipboard = (
) => {
  let timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const handleGetClipboardResult = async (
    onClipboardResult: (text: string) => void
  ) => {
    if (checkIOSPlatform()) return;
    
    const text = await getClipboardText();

    if (!text) return;

    timer.current = setTimeout(() => {
      onClipboardResult(text);
    }, 2000);
  }

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return { handleGetClipboardResult };
};
