import React, { useEffect } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  API,
  getCashOutRoutesData,
  setHeader,
  setPixData,
  Types as UTypes,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Drawer } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useNavigate } from "react-router-dom";
import { ClipboardDrawerContent } from "../../components/ClipboardDrawerContent";
import ErrorDrawerContent from "../../components/ErrorDrawerContent";
import QrCodeReader from "../../components/QrCodeReader";
import {
  disableTorch,
  enableTorch,
  getCapabilities,
  getClipboardText,
} from "../../utils/capabilities";
import { checkIOSPlatform } from "../../utils/ios";
import { checkPixCode, checkPixKey } from "../../utils/pix";
import { PixDecoded } from "./CopyPaste";
import * as Styles from "./styles";
import * as Types from "./types";
import { toast } from "@hyperlocal/vital2";
import { useClipboard } from "../../hooks/useClipboard";

const routes = getCashOutRoutesData();

export const Pix: React.FC = () => {
  const [drawer, setDrawer] = React.useState<Types.IDrawerOptions>({});
  const [torchState, setTorchState] = React.useState<boolean>(false);
  const [torchCapability, setTorchCapability] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [stopScan, setStopScan] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const { handleGetClipboardResult } = useClipboard();

  const toggleDrawerState = (drawerContent, state = !drawer.state) => {
    setDrawer({ state, ...drawerContent });
  };

  const changeHeader = async (data: UTypes["IHeader"]) => {
    setHeader(data);
  };

  const toggleTorch = () => {
    torchState ? disableTorch() : enableTorch();
    setTorchState(!torchState);
  };

  const checkClipboardResult = (text: string) => {
    if (!text) return;

    const codeType = checkPixKey(text);

    if (!codeType) return;

    setError(false);
    const drawerContent: Types.IDrawerOptions = {
      title: "Efetuar Pix?",
      codeType,
      code: text,
    };
    toggleDrawerState(drawerContent);
  };

  const handleClipboardResult = (text) => {
    getCapabilities().then((capabilities) => {
      // @ts-ignore *teste, não é listada no typescript, faremos testes para funcionamento
      setTorchCapability(capabilities.torch);
    });

    checkClipboardResult(text);
  };

  const goToCopyPaste = () => {
    setStopScan(true);

    navigate(routes.cashOutPixCopiaCola);
  };

  const goToPayment = async (code: string) => {
    try {
      const response = await API.pix.post<PixDecoded>(
        "/api/v1/pix/QrCode/decode",
        {
          codeContent: code,
        },
      );
      setPixData(response.data as any);

      setStopScan(true);
      navigate(routes.cashOutPixPayment);
    } catch (error) {
      setError(true);
      const drawerContent: Types.IDrawerOptions = {
        title: "Tivemos um problema",
        description: "O leitor está com dificuldades em reconhecer o Qr code.",
      };
      toggleDrawerState(drawerContent, true);
    }
  };

  const handleDetected = React.useCallback((decodedText: string) => {
    if (checkPixCode(decodedText)) {
      goToPayment(decodedText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeHeader({
      show: false,
    });

    handleGetClipboardResult(handleClipboardResult);

    return () => setDrawer({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styles.PixContainer>
      <Icon
        className="headerIcon close"
        name="SettingTrashClose"
        onClick={() => navigate(routes.dashboard)}
      />
      {torchCapability && (
        <Icon
          className="headerIcon torch"
          name={
            !torchState ? "NotificatinosLampSlash" : "NotificatinosLampCharge"
          }
          onClick={() => toggleTorch()}
        />
      )}
      <QrCodeReader onDetected={handleDetected} />
      <Styles.Container>
        <Styles.QRCodeHelpTitle>
          Posicione a câmera
          <br />
          sobre o QR Code
        </Styles.QRCodeHelpTitle>
        <Styles.QRCodeHelpText>A leitura é automatica</Styles.QRCodeHelpText>
      </Styles.Container>
      <Styles.ButtonWrapper>
        <Button
          variant="secondary"
          onClick={() => navigate(routes.cashOutBoleto)}
        >
          Código de Barras
        </Button>
        <Button onClick={() => goToCopyPaste()}>Pix Copia e Cola</Button>
      </Styles.ButtonWrapper>

      <Drawer
        onDismiss={() => toggleDrawerState(null)}
        position="bottom"
        title={drawer.title}
        open={drawer.state}
      >
        {!error ? (
          <ClipboardDrawerContent
            code={drawer.code}
            codeType={drawer.codeType}
            description={drawer.description}
            title={drawer.title}
            onSubmit={(code: string) => goToPayment(code)}
          />
        ) : (
          <ErrorDrawerContent
            code={drawer.code}
            description={drawer.description}
            onClose={() => toggleDrawerState(null)}
            onSubmit={() => navigate(routes.cashOutPixCopiaCola)}
          />
        )}
      </Drawer>
    </Styles.PixContainer>
  );
};

export default Pix;
