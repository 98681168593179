import { formatBoleto, isValidBoleto } from "@brazilian-utils/brazilian-utils";
import {
  formatCurrency,
  useAccountStore,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Avatar } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

const formatBankSlip = (barCode: string) => {
  if (isValidBoleto(barCode)) return formatBoleto(barCode);

  return barCode;
};

const descriptionStyles = tv({
  slots: {
    definitionTerm:
      "font-base font-medium text-neutral-darkest text-xs/default w-fit",
    definitionDetail: "font-base text-neutral-dark text-xs/md text-end",
    definitionContainer: "grid grid-cols-[auto_1fr] my-4 gap-4 w-full",
  },
});

interface PendingPaymentReceiptProps {
  value: number;
  barcode: string;
}

export const PendingPaymentReceipt = ({
  barcode,
  value,
}: PendingPaymentReceiptProps) => {
  const { account } = useAccountStore();

  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  return (
    <div className="flex h-[100svh] flex-col overflow-hidden pt-6 mobile:h-auto">
      <div className="mb-6 flex w-full flex-col items-center overflow-hidden">
        <div className="flex w-full flex-col items-center overflow-hidden">
          <Avatar.Root
            variant="primary"
            size="lg"
            className="mb-4 mt-8 flex-shrink-0 bg-status-warning-light text-status-warning-light"
          >
            <Avatar.Slot
              name="GeralBarcode"
              fill="currentColor"
              className="!text-status-warning-dark"
            />
          </Avatar.Root>
          <h5 className="font-base text-sm/md font-bold text-neutral-darkest">
            Pagamento de Boleto
          </h5>
          <h5 className="mb-6 font-base text-sm/md font-bold text-status-warning-dark">
            Pendente
          </h5>
          <div className="flex h-full w-full flex-col overflow-hidden">
            <hr className="h-[1px] w-full bg-neutral-light" />
            <dl className={definitionContainer()}>
              <div className="col-span-2">
                <dt className={definitionTerm()}>Código de barras</dt>
                <dd
                  className={twMerge(
                    definitionDetail(),
                    "break-all text-start",
                  )}
                >
                  {formatBankSlip(barcode)}
                </dd>
              </div>
              <dt className={definitionTerm()}>Data do pagamento</dt>
              <dd className={definitionDetail()}>Aguardando aprovação</dd>
              <dt className={definitionTerm()}>Valor</dt>
              <dd className={definitionDetail()}>{formatCurrency(value)}</dd>
              <dt className={definitionTerm()}>Pago por</dt>
              <dd className={definitionDetail()}>
                {account.personInfoResponse?.name ||
                  account.companyInfoResponse.fantasyName}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
