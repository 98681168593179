const checkPixCode = (code: string) => {
  return code?.toLowerCase().includes("br.gov.bcb.pix");
};

const CPFValidator = (cpf) => {
  if (!cpf) return false;

  cpf = cpf.replace(/\D/g, "");
  if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  let result = true;
  [9, 10].forEach(function (j) {
    let sum = 0;
    let r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach(function (e, i) {
        sum += parseInt(e) * (j + 2 - (i + 1));
      });
    r = sum % 11;
    r = r < 2 ? 0 : 11 - r;
    // eslint-disable-next-line eqeqeq
    if (r != cpf.substring(j, j + 1)) result = false;
  });
  return result;
};

const CNPJValidator = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs inválidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let CNPJLength = cnpj.length - 2;
  let CNPJNumbers = cnpj.substring(0, CNPJLength);
  const CNPJDigits = cnpj.substring(CNPJLength);
  let sum = 0;
  let pos = CNPJLength - 7;

  for (let i = CNPJLength; i >= 1; i--) {
    sum += CNPJNumbers.charAt(CNPJLength - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  // eslint-disable-next-line eqeqeq
  if (result != CNPJDigits.charAt(0)) return false;

  CNPJLength = CNPJLength + 1;
  CNPJNumbers = cnpj.substring(0, CNPJLength);
  sum = 0;
  pos = CNPJLength - 7;
  for (let i = CNPJLength; i >= 1; i--) {
    sum += CNPJNumbers.charAt(CNPJLength - i) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  // eslint-disable-next-line eqeqeq
  if (result != CNPJDigits.charAt(1)) return false;

  return true;
};

const phoneValidator = (phone) => {
  const regex =
    /^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$/;
  return regex.test(phone);
};

const randomPixKeyValidator = (pix) => {
  const regex =
    /^([0-9a-zA-Z]{8})+(-([0-9a-zA-Z]{4}))+(-([0-9a-zA-Z]{4}))+(-([0-9a-zA-Z]{4}))+(-([0-9a-zA-Z]{12}))/;
  return regex.test(pix);
};

const checkPixKey = (pix) => {
  if (CPFValidator(pix)) return "CPF";
  if (CNPJValidator(pix)) return "CNPJ";
  if (phoneValidator(pix)) return "Celular";
  if (randomPixKeyValidator(pix)) return "Chave Aleatória";
  if (checkPixCode(pix)) return "Código Pix";
};

export {
  checkPixCode,
  CPFValidator,
  CNPJValidator,
  phoneValidator,
  randomPixKeyValidator,
  checkPixKey,
};
