// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@hyperlocal/banking-utility";
import { AxiosRequestHeaders } from "axios";
import {
  CreatePaymentData,
  CreatePaymentResponse,
  CreateSchedulePaymentResponse,
} from "./types/create-payment";
import { GetExtractParams, GetExtractResponse } from "./types/get-extract";
import { ListScheduleResponse } from "./types/get-schedule";
import { ValidateBarcodeResponse } from "./types/validate-barcode";

const defaultHeaders = (type: "pix" | "manager") => {
  return {
    ...(API[type].defaults.headers as unknown as AxiosRequestHeaders),
  };
};

const apis = {
  QrCodeDecode: async (qrCode: string, accountId: string, userId: string) =>
    await API.pix.post(
      `/api/Pix/${accountId}/qrcode/decode`,
      {
        qrCodePayload: qrCode,
      },
      { headers: { ...defaultHeaders("pix"), userId } },
    ),
  DebitPix: async (
    payload,
    accountId: string,
    userId: string,
    cafToken: string,
  ) =>
    await API.pix.post(
      `/api/Pix/${accountId}/transaction/debit`,
      {
        ...payload,
      },
      {
        headers: {
          ...defaultHeaders("pix"),
          userId,
          cafToken,
        },
      },
    ),
  DebitBoleto: async (payload, accountId: string, cafToken: string) =>
    await API.manager.post(
      `/DigitalAccount/${accountId}/Discharge/boleto`,
      {
        ...payload,
      },
      { headers: { ...defaultHeaders("manager"), cafToken } },
    ),

  readBoleto: async (data: string) =>
    await API.manager.get(`/DigitalAccount/Boleto/${data}/Information`),

  validateBarCode: async (barcode: string) =>
    await API.financePayments.get<ValidateBarcodeResponse>(
      "/api/Payment/validateBarcode",
      {
        params: {
          barcode,
          scheduleNextWindow: false,
        },
      },
    ),

  createPayment: async (
    data: CreatePaymentData & { mfa: string; cafToken: string },
  ) =>
    await API.financePayments.post<CreatePaymentResponse>(
      "/api/Payment/createPayment",
      data,
      {
        headers: {
          cafToken: data.cafToken,
        },
      },
    ),

  createSchedulePayment: async (data: {
    payload: CreatePaymentData;
    mfa: string;
    dateSchedule: string;
    cafToken: string;
  }) =>
    await API.financePayments.post<CreateSchedulePaymentResponse>(
      "/api/Payment/createPaymentScheduler",
      data,
      {
        headers: {
          cafToken: data.cafToken,
        },
      },
    ),

  listSchedule: async (id: string) => {
    const { data } = await API.financePayments.get<ListScheduleResponse>(
      "/api/Payment/listSchedule",
      {
        params: {
          idSchedule: id,
        },
      },
    );

    return data;
  },

  getExtract: async (params: GetExtractParams) =>
    await API.statements.get<GetExtractResponse>("/Extract", { params }),
};

export default apis;
